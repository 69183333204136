
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
















































































































































































































































// .conditions,
// [class*='conditions--'] {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

.conditions-outer {
  @include bg-color;

  padding-top: $spacing * 1.5;
  border-top: 0.1rem solid $c-light-gray;

  @include mq(m) {
    padding-top: $spacing * 2;
  }

  @include mq(l) {
    padding-top: $spacing * 2.5;
  }
}

.conditions__title {
  margin: 0;
  padding-bottom: $spacing * 1.5;
  cursor: pointer;

  @include mq(m) {
    padding-bottom: $spacing * 2;
  }

  @include mq(l) {
    padding-bottom: $spacing * 2.5;
  }
}

.conditions__title__icon {
  margin-left: $spacing / 2;
  transition: transform 0.3s ease-out;

  .is-open & {
    transform: rotate(180deg);
  }
}

.conditions__listing-outer {
  overflow: hidden;
  width: col(12);
  height: 0;
  margin: 0 auto;
  color: $c-gray;
  font-size: 1.2rem;

  strong {
    @extend %fw-normal;
  }
}

.conditions__listing {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  border-top: 1px solid $c-blue-lightest;

  @include mq(m) {
    padding-bottom: $spacing * 3;
  }

  @include mq(l) {
    padding-bottom: $spacing * 4;
  }
}

.conditions__item {
  & + & {
    margin-top: $spacing;
  }
}

.condition__sub-item {
  margin-top: $spacing;
}

.conditions__sub-item__title {
  margin: 0;
  padding-bottom: $spacing * 0.5;
  color: $black;
  font-size: 1.7rem;
  border-bottom: 0.1rem solid $c-light-gray;
}

.conditions__sub-item__item__title {
  margin: $spacing * 1.5 0 $spacing * 0.75;

  [class*='conditions--'][class*='--promo'] & {
    display: none;
  }
}

.conditions__sub-item__item__text {
  margin-top: $spacing * 0.75;
}
